import * as yup from 'yup';

export const MIN_PASSWORD_LENGTH = 8;
export const USER_NAME_REGEX = /^\s*[a-z' -]+\s*$/i;

export const EMAIL_SCHEMA = yup.string().required('Email is required.').email('Invalid email.');
const createPasswordValidationError = 'Password is required. Password has to be at least 8 characters.';
export const CREATE_PASSWORD_SCHEMA = yup
  .string()
  .required(createPasswordValidationError)
  .min(MIN_PASSWORD_LENGTH, createPasswordValidationError);
export const PASSWORD_SCHEMA = yup.string().required('Password is required.');
export const FIRST_NAME_SCHEMA = yup
  .string()
  .trim()
  .required('First name is required.')
  .matches(USER_NAME_REGEX, 'Invalid first name.');
export const LAST_NAME_SCHEMA = yup
  .string()
  .trim()
  .required('Last name is required.')
  .matches(USER_NAME_REGEX, 'Invalid last name.');

export const MAX_TITLE_LENGTH = 100;

/* eslint-disable max-len */
export const apaReferenceExample = `
<p>American Psychological Association. (year). Article title: Capital letter also for subtitle. <i>Name of Journal, volume#</i>(issue#), pg#-pg#.</p>
<p>&nbsp;</p>
<p>Author(s) of essay or chapter. (year). Title of essay or chapter. In F. M. Lastname (Ed.), <i>Book title</i> (pages of essay or chapter). Publisher. <a href="https://doi.org/10.xx.xxxxxxxxxx"><u>https://</u></a>………</p>
`;

export const apaAbstractExample = `
<p>The abstract is the first major section of the paper. It should be a succinct, single-paragraph summary of your paper’s purpose,
and should include the main points, method, findings, and conclusion of your paper.</p>
<p>The abstract should be no shorter than 150 words, or longer than 250 words. Unlike other paragraphs in the paper,
the first line of the abstract should not be indented.</p>
<p>It can be useful to append a short list of keywords to your abstract which enable researchers
and databases to locate your paper more effectively.</p>
<p>&nbsp;</p>
<p><i>Keywords: </i>keyword 1, keyword 2, keyword 3</p>
`;
