import { generatePath } from 'react-router-dom';
import { call } from 'redux-saga/effects';

import {
  BOOKS_ROUTE,
  DOC_ID_ROUTE,
  history,
  NOT_FOUND_ROUTE,
  LOGIN_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  SUBSCRIPTION_ROUTE,
  WELCOME_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  COMPLETE_PASSWORD_RESET_ROUTE,
  NEW_BOOK_ROUTE,
  LOADING_ROUTE,
  FOLDER_ID_ROUTE,
  EDIT_BOOK_ROUTE_ID,
  APPENDIX_ID_ROUTE,
  TABLE_OF_CONTENTS_BOOK_ROUTE,
} from '../config';

import { redirectActions } from './redirect-actions';
import { Book } from '@shared/types';
import { getApaFirstPage, getApaOptionalPages } from './utils';

export function* toBooks() {
  yield call(history.push, BOOKS_ROUTE);
}

export function* toBookEdit(bookId: string) {
  const path = generatePath(EDIT_BOOK_ROUTE_ID, { bookId });
  const backUrl = encodeURIComponent(history.location.pathname);
  yield call(history.push, path + `?backUrl=${backUrl}`);
}

export function* backFromBookEdit() {
  const params = new URLSearchParams(window.location.search);
  yield call(history.push, params.get('backUrl') || BOOKS_ROUTE);
}

export function* toDoc(bookId: string, docId: string) {
  const route = DOC_ID_ROUTE;
  const path = generatePath(route, { bookId, docId });
  yield call(history.push, path);
}

export function* toAppendix(bookId: string, docId: string) {
  const route = APPENDIX_ID_ROUTE;
  const path = generatePath(route, { bookId, docId });
  yield call(history.push, path);
}

export function* toFolder(bookId: string, folderId: string) {
  const path = generatePath(FOLDER_ID_ROUTE, { bookId, folderId });

  yield call(history.push, path);
}

export function* to404() {
  yield call(history.push, NOT_FOUND_ROUTE);
}

export function* toLogin() {
  yield call(history.push, LOGIN_ROUTE);
}
export function* toSettings() {
  yield call(history.push, PROFILE_SETTINGS_ROUTE);
}
export function* toSubscriptions() {
  yield call(history.push, SUBSCRIPTION_ROUTE);
}

export function* toWelcome() {
  yield call(history.push, WELCOME_ROUTE);
}

export function* toRegister() {
  yield call(history.push, REGISTER_ROUTE);
}

export function* toResetPassword() {
  yield call(history.push, RESET_PASSWORD_ROUTE);
}

export function* toCompletePassword(code: string) {
  yield call(history.push.bind(null, COMPLETE_PASSWORD_RESET_ROUTE, code));
}

export function* toNewBookCreation() {
  yield call(history.push, NEW_BOOK_ROUTE);
}

export function* toLoading() {
  yield call(history.push, LOADING_ROUTE);
}

export function* docPageRedirect(toDocPageAction: ReturnType<typeof redirectActions.toDocPageClick>) {
  const {
    payload: { bookId, routePath },
  } = toDocPageAction;
  yield call(history.push, `/book/${bookId}/chapter/${routePath}`);
}

export function* appendixPageRedirect(toAppendixPageAction: ReturnType<typeof redirectActions.toAppendixPageClick>) {
  const {
    payload: { bookId, routePath },
  } = toAppendixPageAction;
  yield call(history.push, `/book/${bookId}/appendix/${routePath}`);
}

export function* folderPageRedirect(toDocPageAction: ReturnType<typeof redirectActions.toFolderPageClick>) {
  const {
    payload: { bookId, routePath },
  } = toDocPageAction;
  yield call(history.push, `/book/${bookId}/folder/${routePath}`);
}

export function* bookFrontBackMatterPagesRedirect(
  toFrontBackMatterAction: ReturnType<typeof redirectActions.toFrontBackMatterClick>
) {
  const {
    payload: { bookId, routePath },
  } = toFrontBackMatterAction;
  yield call(history.push, `/book/${bookId}/${routePath}`);
}

export function* toTitle(bookId: string) {
  yield call(history.push, `/book/${bookId}/title`);
}

export function* toFirstFrontMatterApaPageRedirect(book: Book) {
  const optionalPages = getApaOptionalPages(book.extra);
  const firstPageRoute = getApaFirstPage(optionalPages);

  const routePath = firstPageRoute ?? TABLE_OF_CONTENTS_BOOK_ROUTE;
  const path = generatePath(routePath, { bookId: book.id });

  yield call(history.push, path);
}
