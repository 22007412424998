export const apaSubtitleExample = `A Dissertation/Thesis
  Submitted to the Faculty of
  University Kennesaw State University
  in Partial Fulfillment of the Requirements
  for the Degree of Master of Arts/Master of Landscape Architecture/Master of
  Science/Doctor of Philosophy.
  in Your Major.
  in the Department of Department name.\n
  University Kennesaw State University, Kennesaw, GA\n
  Date of Graduation June 2024`;

export const apaApprovedByListExample = `Committee Chair Name
  (Major Professor/Co-Major Professor/Director of Thesis/Director of Dissertation/Minor
  Professor/Committee Member/Graduate Coordinator)\n\n
  Committee Chair Name
  (Major Professor/Co-Major Professor/Director of Thesis/Director of Dissertation/Minor
  Professor/Committee Member/Graduate Coordinator)\n\n
  Committee Chair Name
  (Major Professor/Co-Major Professor/Director of Thesis/Director of Dissertation/Minor
  Professor/Committee Member/Graduate Coordinator)\n\n
  Committee Chair Name
  (Major Professor/Co-Major Professor/Director of Thesis/Director of Dissertation/Minor
  Professor/Committee Member/Graduate Coordinator)\n\n
  Dean’s Name
  Dean
  College's Name`;
