/* eslint-disable max-len */
const imageSrc =
  'https://firebasestorage.googleapis.com/v0/b/blooksy-252d2.appspot.com/o/examples%2Fimage-example-v2.png?alt=media&amp;token=12bfb8a2-e0db-4f0d-bbf4-f8d82a2e87f3';
export const apaSectionExample = `
 <div>
   <p>The main body of the paper immediately follows the contents page. The first section of the main body is the introduction. The introduction should present the problem, purpose, and thesis (or hypothesis) of the paper. Although the introduction does not require its own heading, the sections following the introduction <i>do </i>require a second-level heading. These are:</p>
   <ol>
      <li>Method</li>
      <li>Results</li>
      <li>Discussion (or Conclusion)&nbsp;</li>
   </ol>
   <p>There are five levels of APA-style headings. The first level is demonstrated at the top of this page. It should be bolded and centered, and the first letter of each word is capitalized.</p>
   <h3>Heading 2</h3>
   <p>The second level of heading is bolded and situated flush left, and the first letter of each word in the heading is capitalized. The paragraph text should be typed on the following line and indented five spaces from the left.</p>
   <h4>Heading 3</h4>
   <p>The third level of heading is bolded, indented five spaces from the left, and followed by a period. Capitalize only the first letter of the first word, and proper nouns. The first paragraph following this heading should be typed on the same line as the heading.</p>
   <h5>Heading 4</h5>
   <p>The fourth level of heading is bolded, italicized, indented five spaces from the left, and followed by a period. Capitalize only the first letter of the first word in the heading and of proper nouns. The first paragraph following this heading should be typed on the same line.</p>
   <h6>Heading 5</h6>
   <p>The fifth level of heading is the same as regular paragraph text, italicized, indented five spaces from the left, and followed by a period.</p>
   <p><br><br><strong>Table 1</strong></p>
   <p><i>Example table body text</i></p>
   <figure class="table" style="float:left;">
      <table style=";">
         <colgroup>
            <col style="width:25%;">
            <col style="width:25%;">
            <col style="width:25%;">
            <col style="width:25%;">
         </colgroup>
         <tbody>
            <tr>
               <td style="vertical-align:top;"><strong>College</strong></td>
               <td style="vertical-align:top;"><strong>New students</strong></td>
               <td style="vertical-align:top;"><strong>Graduating students</strong></td>
               <td style="vertical-align:top;"><strong>Change</strong></td>
            </tr>
            <tr>
               <td style="vertical-align:top;">&nbsp;</td>
               <td style="vertical-align:top;"><i>Undergraduate</i></td>
               <td style="vertical-align:top;">&nbsp;</td>
               <td style="vertical-align:top;">&nbsp;</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Cedar University</td>
               <td style="vertical-align:top;">110</td>
               <td style="vertical-align:top;">103</td>
               <td style="vertical-align:top;">+7</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Elm College</td>
               <td style="vertical-align:top;">223</td>
               <td style="vertical-align:top;">214</td>
               <td style="vertical-align:top;">+9</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Maple Academy</td>
               <td style="vertical-align:top;">197</td>
               <td style="vertical-align:top;">120</td>
               <td style="vertical-align:top;">+77</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Pine College</td>
               <td style="vertical-align:top;">134</td>
               <td style="vertical-align:top;">121</td>
               <td style="vertical-align:top;">+13</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Oak Institute</td>
               <td style="vertical-align:top;">202</td>
               <td style="vertical-align:top;">210</td>
               <td style="vertical-align:top;">-8</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">&nbsp;</td>
               <td style="vertical-align:top;"><i>Graduate</i></td>
               <td style="vertical-align:top;">&nbsp;</td>
               <td style="vertical-align:top;">&nbsp;</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Cedar University</td>
               <td style="vertical-align:top;">24</td>
               <td style="vertical-align:top;">20</td>
               <td style="vertical-align:top;">+4</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Elm College</td>
               <td style="vertical-align:top;">43</td>
               <td style="vertical-align:top;">53</td>
               <td style="vertical-align:top;">-10</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Maple Academy</td>
               <td style="vertical-align:top;">3</td>
               <td style="vertical-align:top;">11</td>
               <td style="vertical-align:top;">-8</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Pine College</td>
               <td style="vertical-align:top;">9</td>
               <td style="vertical-align:top;">4</td>
               <td style="vertical-align:top;">+5</td>
            </tr>
            <tr>
               <td style="vertical-align:top;">Oak Institute</td>
               <td style="vertical-align:top;">53</td>
               <td style="vertical-align:top;">52</td>
               <td style="vertical-align:top;">+1</td>
            </tr>
            <tr>
               <td style="vertical-align:top;"><strong>Total</strong></td>
               <td style="vertical-align:top;">998</td>
               <td style="vertical-align:top;">908</td>
               <td style="vertical-align:top;">90</td>
            </tr>
         </tbody>
      </table>
   </figure>
   <p><br><br><strong>Figure 1</strong></p>
   <p><i>Example figure body text</i></p>
   <figure class="image image_resized">
    <img src=${imageSrc} alt="Image example">
    </figure>
   <p><i>Note. Author, 2018</i></p>
</div>
`;
