import {
  ACKNOWLEDGEMENTS_BOOK_ROUTE,
  APPROVED_BY_BOOK_ROUTE,
  COPYRIGHT_BOOK_ROUTE,
  DEDICATION_BOOK_ROUTE,
  TITLE_BOOK_ROUTE,
} from '../config';
import { Extra, ExtraContent } from 'blooksy-backend';

type OptionalPage = {
  page: ExtraContent;
  route: string;
};

export const getApaOptionalPages = (extra: Extra): OptionalPage[] => {
  const optionalPages = [
    {
      page: extra.title,
      route: TITLE_BOOK_ROUTE,
    },
    {
      page: extra.copyright,
      route: COPYRIGHT_BOOK_ROUTE,
    },
    {
      page: extra.approvedBy,
      route: APPROVED_BY_BOOK_ROUTE,
    },
    {
      page: extra.dedication,
      route: DEDICATION_BOOK_ROUTE,
    },
    {
      page: extra.acknowledgements,
      route: ACKNOWLEDGEMENTS_BOOK_ROUTE,
    },
  ];

  return optionalPages;
};

export const getApaFirstPage = (optionalPages: OptionalPage[]) => {
  for (const { page, route } of optionalPages) {
    if (page.hidden !== true) {
      return route;
    }
  }
  return null;
};
