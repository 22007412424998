import { createAction } from '@reduxjs/toolkit';

import { ToBookAction, ToDocPageAction, ToFrontBackMatterAction } from '@shared/types';

export const redirectActions = {
  toRegisterClick: createAction<void>('redirect/toRegisterClick'),
  toLoginClick: createAction<void>('redirect/toLoginClick'),
  toBookClick: createAction<ToBookAction>('redirect/toBookClick'),
  toEditBookClick: createAction<ToBookAction>('redirect/toEditBookClick'),
  toSettingsClick: createAction<void>('redirect/toSettingsClick'),
  toSubscriptionsClick: createAction<void>('redirect/toSubscriptionsClick'),
  toFrontBackMatterClick: createAction<ToFrontBackMatterAction>('redirect/toFrontBackMatterClick'),
  toDocPageClick: createAction<ToDocPageAction>('redirect/toDocPageClick'),
  toAppendixPageClick: createAction<ToDocPageAction>('redirect/toAppendixPageClick'),
  toFolderPageClick: createAction<ToDocPageAction>('redirect/toFolderPageClick'),
  toBooksClick: createAction<void>('redirect/toBooksClick'),
  toNewBookClick: createAction<void>('redirect/toNewBookClick'),
  backFromBookEditClick: createAction<void>('redirect/backFromBookEditClick'),
  toFirstFrontMatterApaPageRedirect: createAction<void>('redirect/toFirstFrontMatterApaPageRedirect'),
};
