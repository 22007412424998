import React, { FC, useEffect, useState } from 'react';
import { appDataModel } from '@entities/app-data';
import { selectedBookWithError } from '@entities/app-data/model/selectors';
import {
  CreateTextFormData,
  createTextValidationSchema,
  CURRENT_AI_TOOL_NAME,
  DEFAULT_CREATIVITY,
  DESCRIPTION_MAX_LENGTH,
  getRenewDate,
} from '@features/Aven-AI';
import { createNotification, snackbarModel } from '@features/snackbar';
import { Dialog } from '@headlessui/react';
import { dictionary } from '@shared/config';
import { IconMap } from '@shared/sprite';
import { Button, Input, UnsavedModal } from '@shared/ui';
import { Icon } from '@shared/ui/icon';

import { useDispatch, useSelector } from 'react-redux';
import { avenModel } from '../../model';
import { BuyCreditModal } from './BuyCreditModal';
import { ResultItem } from './ResultItem';
import { useHeightCssVariable } from '@shared/hooks/useHeightCssVariable';
import MixpanelService from '@features/mixpanel';
import { hasUnlimitedAvenCoins } from '@shared/lib/permissions';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

type ResultsProps = {
  isLoading: boolean;
  mode: string;
};
const ResultsEmpty: FC<ResultsProps> = ({ isLoading, mode }) => {
  return (
    <div className='flex h-full items-center justify-center text-center text-neutrals-500'>
      {isLoading ? (
        <p className='text-sm'>Сreating text...</p>
      ) : (
        <div>
          <p className='text-sm mb-2'>Results will appear here</p>
          <p className='text-xs'>Aven is in {mode} mode based on the book type</p>
        </div>
      )}
    </div>
  );
};

type Props = {
  opened: boolean;
  setOpened: (value: boolean) => void;
};
export const CreateTextModal: FC<Props> = ({ opened, setOpened }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<CreateTextFormData>({
    resolver: yupResolver(createTextValidationSchema),
    defaultValues: {
      topic: '',
      description: '',
      keywords: '',
    },
  });

  const [buyCreditsOpened, setBuyCreditsOpened] = useState(false);
  const [unsavedResultsOpened, setUnsavedResultsOpened] = useState(false);
  const [unsavedProgress, setUnsavedProgress] = useState(false);

  const currentBook = useSelector(selectedBookWithError);
  const results = useSelector(avenModel.selectors.selectAvenResults);
  const isLoading = useSelector(avenModel.selectors.selectIsLoading);
  const firestoreUser = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const dispatch = useDispatch();

  const formRef = useHeightCssVariable<HTMLFormElement>('--create-text-form-height', opened);

  const [hasUnsaved, setHasUnsaved] = useState(false);
  const [inputsInProgress, setInputsInProgress] = useState(false);
  const currentSavedResults = useSelector(avenModel.selectors.selectCurrentSavedResults);

  const openBuyCoinsModal = () => {
    setBuyCreditsOpened(true);
    MixpanelService.trackBuyCoinsModalOpened();
  };

  useEffect(() => {
    if (isDirty) {
      setInputsInProgress(true);
    } else {
      setInputsInProgress(false);
    }
  }, [isDirty]);

  const onClose = () => {
    dispatch(avenModel.actions.setResults([]));
    dispatch(avenModel.actions.setCurrentSavedResults([]));
    setHasUnsaved(false);
    setOpened(false);
    reset();
    setUnsavedResultsOpened(false);
    setUnsavedProgress(false);
  };

  const onCloseHandler = () => {
    if (hasUnsaved && !currentSavedResults.length) {
      setUnsavedResultsOpened(true);
      return;
    }
    if (inputsInProgress) {
      setUnsavedProgress(true);
      return;
    }
    onClose();
  };

  const createText = ({ topic, description, keywords }: CreateTextFormData) => {
    if (!firestoreUser.avenCoins && !hasUnlimitedAvenCoins(firestoreUser)) {
      dispatch(
        snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.notEnoughCoins))
      );
      return;
    }
    setHasUnsaved(true);
    setInputsInProgress(false);
    dispatch(
      avenModel.actions.askAvenClick({
        topic,
        description,
        keywords,
        bookType: currentBook.type,
        creativity: DEFAULT_CREATIVITY,
      })
    );

    // TODO: Change this after adding other tools
    MixpanelService.trackAIStarted(CURRENT_AI_TOOL_NAME);
  };

  const isApaPaper = currentBook.type === 'apa';
  const bookTypeName = isApaPaper ? currentBook.type.toUpperCase() : currentBook.type;

  return (
    <Dialog open={opened} onClose={onCloseHandler} className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-center justify-center min-h-screen'>
        <Dialog.Overlay className='fixed inset-0 bg-neutrals-1000 opacity-30' />

        <div className='relative flex flex-col rounded mx-auto my-12 bg-neutrals-0 w-200 h-fit-content'>
          <div className='flex items-center justify-between border-b border-neutrals-200 p-4'>
            <Dialog.Title className='font-semibold text-m'>Aven The AI Writer</Dialog.Title>
            <Button
              variant='icon'
              color='tertiary'
              title='Close modal'
              icon={<Icon glyph={IconMap.Cross} />}
              onClick={onCloseHandler}
              className='bg-transparent border-transparent p-2'
            />
          </div>
          <div className='grid grid-cols-2'>
            <form
              ref={formRef}
              onSubmit={handleSubmit(createText)}
              className='border-r h-fit-content border-neutrals-200 px-4 pt-3 pb-4'
            >
              <div className='mb-3'>
                <Input
                  control={control}
                  name='topic'
                  info="Give a short description of your story's topic."
                  type='text'
                  label='Topic'
                  isMandatory
                />
              </div>
              <div className='mb-3'>
                <Input
                  control={control}
                  name='description'
                  info='Tell us more about your story. Write 4-6 sentences with important points you
                  would like Aven to consider when creating your content.'
                  isTextArea
                  minRows={8}
                  label='Describe the topic'
                  isMandatory
                  maxCharacters={DESCRIPTION_MAX_LENGTH}
                />
              </div>
              <div className='mb-3'>
                <Input
                  control={control}
                  name='keywords'
                  info='You can specify some keywords to help Aven
                  create more accurate content for your book, but it is not required.'
                  type='text'
                  label='Key words'
                />
              </div>
              <div className='flex gap-3 items-center'>
                <Button type='submit' disabled={isLoading} loading={isLoading} color='primary' variant='text'>
                  Use Aven AI
                </Button>
                {!hasUnlimitedAvenCoins(firestoreUser) && (
                  <div className='text-xs text-neutrals-500'>
                    <p>
                      <span className={`${!firestoreUser.avenCoins ? 'text-error-500' : ''}`}>
                        {firestoreUser.avenCoins} uses remaining
                      </span>
                      . Renew on {getRenewDate(firestoreUser)}.
                    </p>
                    <p>
                      Need more?{' '}
                      <button
                        type='button'
                        className='text-primary-800  hover:text-primary-600 active:text-primary-500
                      disabled:text-neutrals-400'
                        onClick={openBuyCoinsModal}
                      >
                        Buy coins
                      </button>
                      <BuyCreditModal opened={buyCreditsOpened} setOpened={setBuyCreditsOpened} />
                    </p>
                  </div>
                )}
              </div>
            </form>
            <div style={{ maxHeight: 'var(--create-text-form-height)' }} className='w-full h-full overflow-auto'>
              {!results.length ? (
                <ResultsEmpty mode={bookTypeName} isLoading={isLoading} />
              ) : (
                <div className='pt-3 flex flex-col px-4 pb-4'>
                  <ul className='text-sm'>
                    {results.map((i, idx) => (
                      <ResultItem idx={idx} id={i.id} text={i.text.trim()} key={i.id} />
                    ))}
                  </ul>
                  <Button
                    variant='text'
                    color='secondary'
                    fullWidth
                    disabled={isLoading}
                    loading={isLoading}
                    onClick={handleSubmit(createText)}
                  >
                    Create More
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <UnsavedModal
          title='Unsaved Results'
          opened={unsavedResultsOpened}
          setOpened={setUnsavedResultsOpened}
          onConfirm={onClose}
        >
          You did not save any results. All unsaved results will be lost. Would you like to continue and leave the
          window?
        </UnsavedModal>
        <UnsavedModal title='Leave Aven AI' opened={unsavedProgress} setOpened={setUnsavedProgress} onConfirm={onClose}>
          It looks like you haven&apos;t finished working with Aven AI. Are you sure you want to leave?
        </UnsavedModal>
      </div>
    </Dialog>
  );
};
