import { FrontBackMatterSidebarItem } from '.';

export const frontMatterApaItems: FrontBackMatterSidebarItem[] = [
  { type: 'titlePage', label: 'Title', routePath: 'title', canBeHidden: true, extraFieldName: 'title' },
  { type: 'copyrightPage', label: 'Copyright', routePath: 'copyright', canBeHidden: true, extraFieldName: 'copyright' },
  {
    type: 'approvedBy',
    label: 'Approved By',
    routePath: 'approved-by',
    canBeHidden: true,
    extraFieldName: 'approvedBy',
  },
  { type: 'dedication', label: 'Dedication', routePath: 'dedication', canBeHidden: true, extraFieldName: 'dedication' },
  {
    type: 'acknowledgements',
    label: 'Acknowledgements',
    routePath: 'acknowledgements',
    canBeHidden: true,
    extraFieldName: 'acknowledgements',
  },
  { type: 'tableOfContents', label: 'Table of Contents', routePath: 'table-of-contents', canBeHidden: false },
  { type: 'listOfTables', label: 'List of Tables', routePath: 'list-of-tables', canBeHidden: false },
  { type: 'listOfFigures', label: 'List of Figures', routePath: 'list-of-figures', canBeHidden: false },
  {
    type: 'abstract',
    label: 'Abstract',
    routePath: 'abstract',
    canBeHidden: false,
  },
];

export const backMatterApaItems: FrontBackMatterSidebarItem[] = [
  { type: 'references', label: 'References', routePath: 'references', canBeHidden: false },
];
