import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import { redirectActions } from '@entities/history';
import { CreateBookForm } from '@features/book-creating';
import { Button } from '@shared/ui';

type Props = {
  isWelcome?: boolean;
};

import MixpanelService from '@features/mixpanel';

export const CreateBook = ({ isWelcome }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isWelcome) {
      MixpanelService.trackOnboardingBookCreationShowed();
    }
  }, [isWelcome]);

  return (
    <>
      <Helmet titleTemplate={isWelcome ? 'Welcome to Blooksy' : 'Create Book - Blooksy'} />
      <div className='px-10 pt-7 md:px-6 sm:px-5'>
        <div className='grid grid-cols-12 md:grid-cols-8 sm:grid-cols-4 gap-x-4'>
          <div className='col-start-3 col-end-11 md:col-start-1 md:col-end-9 sm:col-start-1 sm:col-end-5'>
            <div className='flex items-center justify-between mb-7'>
              <h2 className='mr-4 font-semibold text-l text-neutrals-1000'>
                {isWelcome ? 'Get started on your first book!' : 'Ready to start writing?'}
              </h2>
              <Button
                variant='text'
                color='secondary'
                type='button'
                onClick={() => {
                  dispatch(redirectActions.toBooksClick());
                }}
              >
                {isWelcome ? 'Skip' : 'Cancel'}
              </Button>
            </div>
            <p className='mb-4 font-semibold text-s text-neutrals-1000'>Please select your writing habitat</p>
          </div>
        </div>
      </div>
      <CreateBookForm />
    </>
  );
};
