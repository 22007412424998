/* eslint-disable max-len */
import { serverTimestamp } from 'firebase/firestore';

import { Book, Doc, Folder } from '@shared/types';
import { BookType } from 'blooksy-backend';
import { apaApprovedByListExample, apaSubtitleExample } from './const';

export const getServerTime = () => serverTimestamp();

export const defaultBookFolded = {
  frontMatter: false,
  book: false,
  backMatter: false,
  appendices: false,
};

const extraContent = () => ({ hidden: false, ckCloudId: '' });
const extraContentText = (text = '') => ({ contentText: text, hidden: false });

export const createDefaultBook = (copyrightText = '', type: BookType): Book => {
  const isApaPaper = type === 'apa';

  return {
    authorUserId: '',
    id: '',
    title: '',
    subtitle: '',
    author: '',
    type: 'non-fiction',
    coverImageURL: '',
    _firstChapterId: '',
    titleContent: isApaPaper ? apaSubtitleExample : '',
    approvedByContent: isApaPaper ? apaApprovedByListExample : '',
    copyright: extraContentText(copyrightText),
    extra: {
      title: extraContent(),
      copyright: extraContent(),
      aboutAuthor: extraContent(),
      acknowledgements: extraContent(),
      dedication: extraContent(),
      foreword: extraContent(),
      introduction: extraContent(),
      preface: extraContent(),
      abstract: extraContent(),
      approvedBy: extraContent(),
      listOfFigures: extraContent(),
      listOfTables: extraContent(),
      references: extraContent(),
    },
    createdAt: 0,
    updatedAt: 0,
    settings: { folded: defaultBookFolded, style: isApaPaper ? 'times-new-roman' : 'default' },
  };
};

export const generateCopyrightText = (authorName: string, type: BookType) => {
  const isApaPaper = type === 'apa';

  const currentYear = new Date().getFullYear();

  return isApaPaper
    ? `Copyright by\n\n ${authorName}\n\n Year of Graduation (June 2024)`
    : `Copyright © ${currentYear} by ${authorName}\n
All rights reserved. No part of this book may be reproduced or used in any manner without written permission of the copyright owner except for the use of quotations in a book review. For more information, address: <author email>\n
<First, Second, Third> Edition <Month/Year>\n
(Optional) Book cover design by <Designer Name>\n
ISBN <insert ISBN>\n
www.<insertauthorwebsite>.com`;
};
const defaultDocAndFolderCommon = {
  id: '',
  title: '',
  order: 0,
  noteHTML: '',
  transcriptionText: '',
  avenSavedResults: [],
};
export const createDefaultDoc = (): Doc => ({
  __isDoc: true,
  ckCloudId: '',
  createdAt: 0,
  updatedAt: 0,
  _previewText: '',
  ...defaultDocAndFolderCommon,
});

export const createDefaultFolder = (): Folder => ({
  __isDoc: false,
  ...defaultDocAndFolderCommon,
});
